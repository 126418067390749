




















import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";

@Component
export default class SygniToggleSwitch extends Vue {

  @Prop() label!: String;
  @Prop() value!: boolean;
  @Prop({default:''}) tooltip: String;
  @Prop({default: false}) disabled: boolean;
  @Watch('value', {immediate: true}) onValueChange(){
    this.modelValue = this.value;
  }
  modelValue: boolean = false;

  @Emit('input')
  handleInput(): boolean {
    return this.modelValue;
  }

  mounted(){
    setTimeout( () => {
      // @ts-ignore: Object is possibly 'null'.
      this.$el.querySelector('input').classList.add('animation');
    }, 200);
    this.modelValue = this.value;
  }

  clickCheckbox(){
    // @ts-ignore: Object is possibly 'null'.
    this.$el.querySelector('input').click();
  }
}
