var render = function () {
  var _vm$validation;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-input', _vm.disabled ? 'disabled' : '', (_vm$validation = _vm.validation) !== null && _vm$validation !== void 0 && _vm$validation.$error ? 'error' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "sygni-textarea-input"
  }, [_c('div', {
    staticClass: "sygni-textarea-input__field"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modelValue,
      expression: "modelValue"
    }],
    attrs: {
      "rows": "4",
      "maxlength": _vm.maxLength,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.modelValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.modelValue = $event.target.value;
      }, _vm.handleInput],
      "blur": _vm.onBlur
    }
  })]), _vm.maxLength ? _c('div', {
    staticClass: "sygni-textarea-input__counter"
  }, [_vm._v(_vm._s(_vm.messageLength) + "/" + _vm._s(_vm.maxLength))]) : _vm._e()]), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }