













import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
@Component({
  components: {SygniInputError}
})
export default class SygniTextArea extends Vue {

  messageLength: number = 0;

  @Prop() validation: any;
  @Prop() label!: String;
  @Prop({default: ''}) value!: String;
  @Prop({default: false}) disabled: boolean;
  @Prop() maxLength?: number;
  @Prop({default: null}) testId: string;
  @Watch('value', {immediate: true}) onValueChange(){
    this.modelValue = this.value;
  }
  modelValue: String = '';

  @Emit('input')
  handleInput(): String {
    this.messageLength = this.modelValue.length;
    return this.modelValue;
  }

  @Emit('blur')
  onBlur() {}

  mounted(){
    this.messageLength = this.value?.length ? this.value.length : 0;
    this.modelValue = this.value;
  }
}
